<template>
  <section class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Percentual</a>
        </li>
      </ul>
    </nav>

    <b-loading
      :is-full-page="true"
      :active.sync="load"
      :can-cancel="false"
    ></b-loading>

    <div class="export">
      <button class="button is-primary" @click="planilha">Exportar</button>
    </div>

    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="get">
          <b-field label="Grupo" v-if="hasProfile('admin')">
            <b-select v-model="query.team" placeholder="Grupo">
              <option v-for="team in teams" :key="team._id" :value="team._id">{{
                team.name
              }}</option>
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Curso">
            <b-select v-model="query.curso" placeholder="Curso">
              <option
                v-for="course in courses"
                :key="course._id"
                :value="course._id"
                >{{ course.name }}</option
              >
              <option :value="null">Nenhum</option>
            </b-select>
          </b-field>

          <b-field label="Usuário">
            <Multiselect
              v-model="users"
              id="ajax"
              label="name"
              track-by="name"
              placeholder="Digite para pesquisar"
              open-direction="bottom"
              :options="searchList"
              :multiple="false"
              :searchable="true"
              :loading="isLoadingMultiSelect"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preselect-first="false"
              @search-change="getBySearch"
              selectLabel="Pressione enter para selecionar"
              deselectLabel="Pressione enter para desmarcar"
              selectedLabel="Selecionado"
            >
              <template slot="noOptions">Lista vazia</template>
            </Multiselect>
          </b-field>

          <div class="columns">
            <div class="column">
              <b-field label="Progresso inicial">
                <b-slider size="is-small" v-model="query.init"></b-slider>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Progresso final">
                <b-slider size="is-small" v-model="query.end"></b-slider>
              </b-field>
            </div>
          </div>

          <button class="button is-primary">Buscar</button>
          <button
            type="button"
            class="button is-danger m-l-sm"
            @click="(query = {}), (users = [])"
          >
            Limpar
          </button>
        </form>
      </div>
    </b-collapse>

    <div>
      <h1 v-if="total">Total: {{ total }}</h1>

      <h1 v-if="!total && !isLoading">Progresso não encontrado.</h1>
    </div>

    <b-table
      :data="data"
      default-sort-direction="asc"
      :striped="true"
      :hoverable="true"
      :loading="isLoading"
      v-if="total || isLoading"
    >
      <template slot-scope="props">
        <b-table-column field="user.name" label="Nome" sortable>{{
          props.row.user ? props.row.user.name : ""
        }}</b-table-column>

        <b-table-column field="user.username" label="Login" sortable>{{
          props.row.user ? props.row.user.username : ""
        }}</b-table-column>

        <b-table-column field="user.teams.name" label="Grupo" sortable>{{
          props.row.user ? props.row.user.teams[0].name : ""
        }}</b-table-column>

        <b-table-column field="course.name" label="Curso" sortable>{{
          props.row.course ? props.row.course.name : ""
        }}</b-table-column>

        <b-table-column
          field="currentModule.name"
          label="Módulo atual"
          sortable
          >{{
            props.row.currentModule ? props.row.currentModule.name : ""
          }}</b-table-column
        >

        <b-table-column
          field="currentLesson.name"
          label="Lição atual"
          sortable
          >{{
            props.row.currentLesson ? props.row.currentLesson.name : ""
          }}</b-table-column
        >

        <b-table-column
          field="progress"
          label="Progresso"
          numeric
          centered
          sortable
        >
          <span class="tag is-success">{{
            props.row.progress ? props.row.progress.toFixed(2) + "%" : "0%"
          }}</span>
        </b-table-column>
      </template>
    </b-table>

    <div class="custom">
      <b-pagination
        v-if="total"
        class="m-t-md"
        :total="total"
        :current.sync="current"
        :simple="false"
        :rounded="false"
        :per-page="perPage"
        aria-next-label="Próxima pagina"
        aria-previous-label="Pagina anterior"
        aria-page-label="Page"
        aria-current-label="Pagina atual"
      ></b-pagination>
    </div>
  </section>
</template>

<script>
import Axios from "axios";
import { debounce } from "lodash";
import XLSX from "xlsx";

import { hasProfile } from "@/global/auth";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      data: [],
      teams: [],
      users: [],
      query: {},
      courses: {},
      hasProfile,
      total: "",
      current: 1,
      perPage: "",
      search: null,
      isLoading: false,
      isLoadingMultiSelect: false,
      searchList: [],
      load: null,
      me: null
    };
  },
  methods: {
    async planilha() {
      // let wb = XLSX.utils.table_to_book(document.getElementsByClassName("table"));

      this.load = true;
      let status = [];
      let vezes = Math.round(this.total / 1000);
      if (this.total > 0 && this.total <= 1000) vezes = 1;
      for (let index = 0; index < vezes; index++) {
        let dados = await Axios.get(
          `${
            this.me
              ? "/v1/user-status/team/" + this.me.teams[0]
              : "/v1/user-status"
          }?limit=1000&page=${index + 1}${
            this.users._id ? "&user=" + this.users._id : ""
          }${this.query.team ? "&team=" + this.query.team : ""}${
            this.query.curso ? "&course=" + this.query.curso : ""
          }${this.query.init ? "&progress[$gte]=" + this.query.init : ""}${
            this.query.end ? "&progress[$lte]=" + this.query.end : ""
          }`
        );

        for (const iterator of dados.data.data) {
          status.push(iterator);
        }
      }

      status = status.map(item => {
        return {
          nome: item.user ? item.user.name : "",
          login: item.user ? item.user.username : "",
          grupo: item.user ? item.user.teams[0].name : "",
          curso: item.course ? item.course.name : "",
          modulo_atual: item.currentModule ? item.currentModule.name : "",
          licao_atual: item.currentLesson ? item.currentLesson.name : "",
          progresso: item.progress ? item.progress.toFixed(2) + "%" : "0%",
        };
      });

      let ws = XLSX.utils.json_to_sheet(status);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      XLSX.writeFile(wb, "report.xlsx");
      this.load = null;
    },
    getBySearch: debounce(function() {
      this.isLoadingMultiSelect = true;
      if (arguments[0]) {
        Axios.get(
          `/v1/${this.me ? "users/team/" + this.me.teams[0] : "users"}?name=${
            arguments[0]
          }`
        )
          .then(res => {
            this.searchList = res.data.data;
            this.isLoadingMultiSelect = false;
          })
          .catch(erro => {
            this.isLoadingMultiSelect = false;
            console.error(erro);
          });
      }
      this.isLoadingMultiSelect = false;
    }, 500),
    get() {
      this.isLoading = true;
      Axios.get(
        `${
          this.me
            ? "/v1/user-status/team/" + this.me.teams[0]
            : "/v1/user-status"
        }?page=${this.current}${
          this.users._id ? "&user=" + this.users._id : ""
        }${this.query.team ? "&team=" + this.query.team : ""}${
          this.query.curso ? "&course=" + this.query.curso : ""
        }${this.query.init ? "&progress[$gte]=" + this.query.init : ""}${
          this.query.end ? "&progress[$lte]=" + this.query.end : ""
        }`
      )
        .then(data => {
          this.data = data.data.data;
          this.total = data.data.total;
          this.perPage = data.data.limit;
          this.isLoading = false;
          if (this.hasProfile("admin")) this.getTeam();
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getUser() {
      Axios.get(`/v1/${this.me ? "users/team/" + this.me.teams[0] : "users"}`)
        .then(data => {
          if (data.data) {
            this.users = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then(data => {
          this.me = data.data;
          this.get();
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then(data => {
          if (data.data) {
            this.courses = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getTeam() {
      Axios.get(`/v1/teams/`, { params: { limit: 50 } })
        .then(data => {
          if (data.data) {
            this.teams = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  watch: {
    current(current) {
      this.get(current);
    }
  },
  mounted() {
    this.hasProfile("manager") ? this.getMe() : this.get();
    this.getCourse();
    // this.getExame();
  }
};
</script>

<style lang="scss" scoped>
.custom {
  display: flex;
  justify-content: flex-end;
}
.export {
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
}
</style>
